
          $font-path: "/Users/harsh/Development/Projects/holotrak/01-code/github/synced-holotrak-web/src/assets/fonts";
        
$fontFamily: 'SFProDisplay';

//Colors Starts
$colorGreyDark1: #3D4A57;
$colorGreyDark2: #7E7E7E;
$colorGreyDark3: #979797;
$colorGreyDark4: #D1D1D1;
$colorGreyDark5: #D6D6D6;
$colorGreyDark6: #DBDFE3;
$colorGrey: #DFE3EF;
$colorGrey1: #edf0f7;
$colorGreyLight0: #F8F8F8;
$colorGreyLightBlue: #eef4f5;
$colorGreyLight1: #F9F9F9;
$colorGreyLight2: #F3F3F3;
$colorGreyLight3: #F0F0F0;
$colorGreyLight4: #E0E3E7;

$colorWhite: #FFF;
$colorMuted: #909FAF;
$colorBlack: #000000;
$colorBlackTransparent: #000000D9;
$colorBlackLighter: #282930;
$colorBG: $colorBlack;

$colorYellowDark: #FFA72A;
$colorYellow: #FFB100;
$colorYellowLight: #FFD576;

$colorGreenDarker: #2AC19F7F;
$colorGreenDark: #2BA895;
$colorGreen: #2AC19F;
$colorGreenLight: #29C19E;
$colorGreenLighter: #A8D6CF;

$colorBlue: #48C0FF;
$colorBlueLight: #9BD8CF;
$colorBlueLighter: #89E5D1;
$colorBlueLightest: #9CE8D7;

$colorPink: #F9979A;
$colorPinkDarkest: #F28287;
$colorPinkDarker: #F88387;
$colorPinkDark: #FF8989;
$colorPinkLight: #FFC3C5;

$shadowColorLabelLight: $colorWhite;
$shadowColorLabelDark: #00000066;

$textColorLabelLight: $colorBlack;
$textColorLabelDark: #cccccc;

//Colors Ends

// Font Sizes Starts
$textBig: 18px;
$textExtraLarge: 16px;
$textLarge: 14px;
$textNormal: 12px;
$textSuper: 11px;
$textSmall: 10px;
$textExtraSmall: 9px;
$textTiny: 8px;
// Font Sizes Ends


// Space Starts
$spacingNone: 0px;
$spacingMicro: 2px;
$spacingTiny: 4px;
$spacingLargeTiny: 6px;
$spacingExtraSmall: 8px;
$spacingSmall: 10px;
$spacingMedium: 12px;
$spacingLessThanLarge: 14px;
$spacingLarge: 16px;
$spacingLarge-1: 18px;
$spacingExtraLarge: 20px;
$spacingExtraExtraLarge: 24px;
$spacingExtraExtraExtraLarge: 26px;
$spacingLessThanWide: 28px;
$spacingLessWide: 30px;
$spacingWide: 32px;
$spacingExtraWide: 34px;
$spacingUltraWide: 36px;
$spacingSmall4x: 40px;
$spacingExtended: 42px;
$spacingDouble: 48px;
$spacingExtraDouble: 60px;
// Space Ends

// Indoor Assets
$colorContainer: #2b2b2b;
$colorWall: #2a404b;
$colorFloor: #2a404b; // #595959
$colorText: #5d6975;
$colorOutlines: #5a5a5a;
$colorStairsOuter: $colorOutlines; //#3f444a;
$colorHighlight: #8d5818;

@import "fonts";
@import "itemDetails";

html,
body {
  margin: 0;
  padding: 0;

  font-family: $fontFamily;

  * {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: $colorGreyLight2;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      background: $colorBlack;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $colorBlack;
    }
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-radius: 0;
  }
}

.ant-form-item-label > label {
  font-weight: bold;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.left-drawer-toggle {
  position: absolute;
  top: 10px;
  right: auto;
  bottom: auto;
  left: 10px;

  z-index: 1000;
  border-radius: 0;
}

.left-drawer-closer {
  position: absolute;
  top: -20px;
  right: $spacingLargeTiny;

  svg {
    width: $spacingExtraExtraExtraLarge;
    height: $spacingExtraExtraExtraLarge;
  }

  box-shadow: none;

  transform: rotate(180deg);

  z-index: 1000;
  border-radius: 0;

  &.ant-float-btn-default {
    background: none;

    .ant-float-btn-body {
      background: none;

      .ant-float-btn-content .ant-float-btn-icon {
        display: flex;
        width: $spacingLessThanWide;
        height: $spacingLessThanWide;
      }
    }
  }
}

.ant-layout {
  .ant-layout-footer {
    bottom: 0;
    padding: 0;

    &.bottom-logo {
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
