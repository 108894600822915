.ant-tooltip {
  .ant-typography {
    color: $colorWhite;
  }
}

.status-indicator {
  width: $spacingSmall;
  height: $spacingSmall;

  margin: 0 10px 0 0;

  border-radius: 50%;
  display: inline-block;
  background-color: rgb(144, 159, 175);

  &.status-indicator--online {
    background-color: $colorGreen;
  }

  &.status-indicator--offline {
    background-color: $colorPink;
  }

  &.status-indicator--gps_not_connected {
    background-color: $colorBlue;
  }

  &.status-indicator--not_connected {
    background-color: $colorMuted;
  }

  &.status-indicator--stopped_reporting {
    background-color: $colorBlack;
  }
}

.signal-indicator {
    &.signal-indicator--excellent {
        color: $colorGreen;
    }

    &.signal-indicator--good {
        color: $colorYellow;
    }

    &.signal-indicator--poor {
        color: $colorPink;
    }

    &.signal-indicator--bad {
        color: $colorMuted;
    }
}

.setting-indicator {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 8px;

  color: $colorWhite;
  background-color: $colorMuted;

  &.setting-indicator--on {
    background-color: $colorGreen;


    &.setting-indicator--warning {
      background-color: $colorYellow;
    }

    &.setting-indicator--danger {
      background-color: $colorPink;
    }

  }

  &.setting-indicator--off {
    // background-color: $colorGreen;


    &.setting-indicator--warning {
      background-color: $colorYellow;
    }

    // &.setting-indicator--danger {
    //   background-color: $colorPink;
    // }

  }
}


.ant-spin-container {
  .ant-row {
    .device-history {
      padding: 20px 0 20px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #dfe3ef;
      border-bottom: 1px solid #dfe3ef;

      .ant-space-item {
        span {
          font-size: 12px;
        }
      }
    }
  }
}

.vehicle-name,
.device-name {
  display: flex;
  align-items: center;
  padding-bottom: 15px;

  h2 {
    font-size: 18px;
    margin-bottom: 0;
  }

  button {
    padding: $spacingTiny $spacingLarge $spacingNone $spacingNone;
  }
}

h5.device-detail-title {
  font-size: $textNormal;
  text-transform: uppercase;
}


.item-details-container {
  padding: 24px 24px 0;
  background: #fff;
  min-height: 64px;

  .item-details--title {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 22px;

    & > div:first-child {
      width: 25px;
    }

    & > div:nth-child(2) {
      width: calc(100% - 80px);
    }

    & > div:last-child {
      margin-left: auto;
      width: 55px;

      button {
        margin-left: 14px;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    h4 {
      margin-bottom: 0;
      font-size: 20px;
      color: #000;
      font-weight: 700;
      margin-left: 10px;
      line-height: 24px;

      button {
        width: 24px;
        height: 24px;
        position: absolute;
        margin-left: 10px;

        svg {
          fill: #fff;
          width: 22px;
          height: 22px;

          path {
            fill: #000;
          }
        }
      }
    }

    button {
      width: auto;
      height: auto;
      padding: 0;
      line-height: 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .device-main-icons-actions {
    button {
      width: 35px;
      height: 35px;
      border-color: $colorGrey1;
      display: flex;
      justify-content: center;
      align-items: center;

      &.renew-icon {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.locate-icon {
        svg {
          width: 14px;
          height: 14px;
        }
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .device-main-slider {
  }
}

.device-inner {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  overflow-x: hidden;

  .ant-row {
    line-height: 20px;
  }

  > .ant-row {
    overflow-y: initial !important;
  }
}

.device-history {
  background: #fff;

  .ant-space-item {
    padding: 0 24px;

    span {
      font-size: 14px !important;
      font-weight: 700;
    }

  }
}


.vechicle-content-det {
  border-top: 1px solid #dfe3ef;
  padding: 20px 15px;

  h4 {
    font-size: 12px;
  }

  .ant-space-item {
    font-size: 12px;
    line-height: 23px;
  }
}

.output-device-new {
  .ant-row {
    line-height: 30px;
  }
}

.driver-main {
  .ant-layout-content {
    padding: 0 !important;
  }
}

.driver-title-new {
  background: #fff;
  padding: 20px 20px 20px;

  h2 {
    font-size: 18px;
    margin-bottom: 0;
  }

  .ant-col {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }
}

.driver-layout-new {
  padding: 30px 20px;
  border-top: 1px solid #dfe3ef;

  h4 {
    font-size: $textNormal;
  }

  .ant-space-item {
    font-size: 12px;
    line-height: 20px;
  }
}

.indoor-assets-overview-new,
.vehicle-overview-new,
.driver-overview-new,
.device-overview-new,
.input-device-new,
.output-device-new {
  border-bottom: 1px solid #dfe3ef;
  padding: 12px;

  .ant-row {
    .ant-col:first-child:last-child {
      padding-left: 0px;
    }

    .ant-col:last-child {
      padding-left: 40px;
    }
  }

  .status-indicator {
    margin-right: 0;
  }

  .ant-space {
    margin-bottom: 4px;
  }

  .item-overview-row {
    .overview-left {
      .ant-space .ant-space-item {
        &:first-child {
          width: 18px;
        }

        &:last-child {
          font-size: $textSmall;
          width: calc(100% - 20px);

          &:after {
            content: ':';
          }
        }
      }
    }

    .overview-right {
      font-size: $textSmall;
      line-height: 20px;
      padding-left: 20px;
      padding-right: 40px;

      .ant-typography {
        font-size: $textSmall;
      }

      span {
        font-size: 10px;
        color: $colorBlack;
      }

      .item-detail--editable-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;

        .item-detail-edit {
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.output-device-new .ant-row .ant-col {
  .ant-space {
    margin-bottom: 0;
  }

  &:first-child:last-child {
    justify-content: flex-start;
  }

  &:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.device-inner h5 {
  text-transform: uppercase;
  font-size: $textNormal;
  font-weight: 700;
  color: #000;
}
