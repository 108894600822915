.main-calendar {
    display: flex;
}
.calendar {
    /* max-width: 900px; */
    /* margin: 0 auto; */
    width: -webkit-fill-available;
    font-family: Arial, sans-serif;
    background: #edf0f7;
    padding: 0px 18px 18px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 11px;
    margin-bottom: 16px;
}

.dropdown-container select {
    padding: 5px;
    font-size: 14px;
    background: transparent;
    border: none;
}

.arrow-buttons {
    margin-left: 10px;
    background: transparent;
    border: none;
}

.arrow-buttons button {
    /* padding: 5px 10px; */
    font-size: 21px;
    cursor: pointer;
    background: transparent;
    border: none;
}

/* Label Section Styling */
.labels {
    display: flex;
    justify-content: space-around;
    gap: 30px;
}

.label {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    color: white;
}

.label.completed {
    background-color: #44c28d;
}

.label.overdue {
    background-color: #f46666;
}

.label.scheduled {
    background-color: #79c2fc;
}

.label.other {
    background-color: #d1d1d1;
}
.task-item.completed {
    border-left: 5px solid #44c28d;
}

.task-item.overdue {
    border-left: 5px solid #f46666;
}

.task-item.scheduled {
    border-left: 5px solid #79c2fc;
}

.task-item.other {
    border-left: 5px solid #d1d1d1;
}

.grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
}

.empty {
    height: 80px;
}

.day {
    background-color: white;
    padding: 10px;
    min-height: 110px;
    position: relative;
}

.day-number {
    position: absolute;
    top: 5px;
    left: 5px;
    font-weight: bold;
}

.event-list {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 0px;
}

.event {
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    color: white;
}

.completed {
    background-color: #44c28d;
    border-radius: 30px;
}

.overdue {
    background-color: #f46666;
    border-radius: 30px;
}

.scheduled {
    background-color: #79c2fc;
    border-radius: 30px;
}

.other {
    background-color: #d1d1d1;
    border-radius: 30px;
}
.delete-btn {
    background: transparent;
    color: black;
    text-align: right;
    float: right;
    border: none;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal h2 {
    margin-top: 0;
}

.modal input,
.modal select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-buttons button {
    padding: 10px 20px;
    cursor: pointer;
}

.task-list {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    width: 400px;
}

.task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    margin: 10px 0;
    padding: 10px;
    border-radius: 0px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-left: 5px solid;
}

.task-info {
    display: flex;
    flex-direction: column;
}

.task-info h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.task-details {
    display: flex;
    gap: 10px;
    font-size: 14px;
    color: #888;
}

.task-distance,
.task-time {
    display: inline-block;
}

.task-action .task-button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
}

.day-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    margin-bottom: 3px;
    gap: 2px;
}

.day-name {
    padding: 10px;
    background: white !important;
}
.prev-month-day {
    color: #667684;
}
.next-month-day {
    color: #667684;
}

