@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay-Regular.ttf');
    font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay-Medium.ttf');
    font-weight: 400;
  font-style: auto;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SFProDisplay-Bold.ttf');
  font-weight: 700;
  font-style: auto;
}
