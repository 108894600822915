
          $font-path: "/Users/harsh/Development/Projects/holotrak/01-code/github/synced-holotrak-web/src/assets/fonts";
        
$fontFamily: 'SFProDisplay';

//Colors Starts
$colorGreyDark1: #3D4A57;
$colorGreyDark2: #7E7E7E;
$colorGreyDark3: #979797;
$colorGreyDark4: #D1D1D1;
$colorGreyDark5: #D6D6D6;
$colorGreyDark6: #DBDFE3;
$colorGrey: #DFE3EF;
$colorGrey1: #edf0f7;
$colorGreyLight0: #F8F8F8;
$colorGreyLightBlue: #eef4f5;
$colorGreyLight1: #F9F9F9;
$colorGreyLight2: #F3F3F3;
$colorGreyLight3: #F0F0F0;
$colorGreyLight4: #E0E3E7;

$colorWhite: #FFF;
$colorMuted: #909FAF;
$colorBlack: #000000;
$colorBlackTransparent: #000000D9;
$colorBlackLighter: #282930;
$colorBG: $colorBlack;

$colorYellowDark: #FFA72A;
$colorYellow: #FFB100;
$colorYellowLight: #FFD576;

$colorGreenDarker: #2AC19F7F;
$colorGreenDark: #2BA895;
$colorGreen: #2AC19F;
$colorGreenLight: #29C19E;
$colorGreenLighter: #A8D6CF;

$colorBlue: #48C0FF;
$colorBlueLight: #9BD8CF;
$colorBlueLighter: #89E5D1;
$colorBlueLightest: #9CE8D7;

$colorPink: #F9979A;
$colorPinkDarkest: #F28287;
$colorPinkDarker: #F88387;
$colorPinkDark: #FF8989;
$colorPinkLight: #FFC3C5;

$shadowColorLabelLight: $colorWhite;
$shadowColorLabelDark: #00000066;

$textColorLabelLight: $colorBlack;
$textColorLabelDark: #cccccc;

//Colors Ends

// Font Sizes Starts
$textBig: 18px;
$textExtraLarge: 16px;
$textLarge: 14px;
$textNormal: 12px;
$textSuper: 11px;
$textSmall: 10px;
$textExtraSmall: 9px;
$textTiny: 8px;
// Font Sizes Ends


// Space Starts
$spacingNone: 0px;
$spacingMicro: 2px;
$spacingTiny: 4px;
$spacingLargeTiny: 6px;
$spacingExtraSmall: 8px;
$spacingSmall: 10px;
$spacingMedium: 12px;
$spacingLessThanLarge: 14px;
$spacingLarge: 16px;
$spacingLarge-1: 18px;
$spacingExtraLarge: 20px;
$spacingExtraExtraLarge: 24px;
$spacingExtraExtraExtraLarge: 26px;
$spacingLessThanWide: 28px;
$spacingLessWide: 30px;
$spacingWide: 32px;
$spacingExtraWide: 34px;
$spacingUltraWide: 36px;
$spacingSmall4x: 40px;
$spacingExtended: 42px;
$spacingDouble: 48px;
$spacingExtraDouble: 60px;
// Space Ends

// Indoor Assets
$colorContainer: #2b2b2b;
$colorWall: #2a404b;
$colorFloor: #2a404b; // #595959
$colorText: #5d6975;
$colorOutlines: #5a5a5a;
$colorStairsOuter: $colorOutlines; //#3f444a;
$colorHighlight: #8d5818;


.tab-content-wrapper {
    padding: $spacingDouble 0;

    .ant-form-item-required {
        width: 200px;
    }

    .language-input {
        label {
            width: 200px;
        }
    }

    .ant-form-item-control-input-content {
        .ant-input {
            height: $spacingDouble;
            background-color: $colorWhite;
            border-radius: $spacingWide;
        }

        .ant-select-selector {
            height: $spacingDouble;
            background-color: $colorWhite;
            border-radius: $spacingWide;
        }
    }

    .ant-form-item-control-input {
        width: 300px;
    }

    .language-input {
        .ant-select-selection-search {
            input {
                height: $spacingDouble;
                border-radius: $spacingWide;
            }
        }
    }

    .ant-form-item-control-input {
        .ant-form-item-control-input-content {
            button {
                margin-left: 33px;
                padding: $spacingExtraSmall $spacingExtraExtraLarge $spacingWide;
                border-radius: $spacingWide;
            }
        }
    }

    .ant-form-item-label {
        label {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .ant-table-wrapper {
        width: 100%;
    }
}

.settings-sidebar .ant-tabs-nav-list {
    height: 100vh;
}

.ant-tabs-left {
    .ant-tabs-tab {
        margin: 0 !important;
        padding: $spacingExtraSmall $spacingExtraExtraLarge !important;
    }

    .ant-tabs-nav {
        background: $colorGreyLight1;
        border-right: 1px solid $colorGrey;
        box-shadow: 0 0 $spacingExtraSmall 0 rgb(0 0 0 / 30%);
        width: 220px;
    }

    .ant-tabs-tab-btn {
        width: 100%;

        .ant-space {
            display: flex;
            text-align: left;

            .ant-space-item {
                width: 50%;

                &:nth-child(2) {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}

.grid-view {
    margin-top: $spacingExtraLarge;

    .grid-view-inner {
        display: flex;
        flex-wrap: wrap;
        height: calc(100vh - $spacingLarge);
        overflow: auto;

        &::-webkit-scrollbar {
            width: $spacingExtraSmall;
            background: $colorGreyDark4;
        }

        &::-webkit-scrollbar-thumb {
            background: $colorGreyDark3;
            height: 3px;
            border-radius: $spacingTiny;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $colorGreyDark3;
        }

        .setting-grid {
            width: calc((100% - 110px) / 3);
            margin-top: $spacingWide;
            margin-left: $spacingWide;
            background-color: $colorWhite;

            &:nth-child(3n+1) {
                margin-left: 0;
            }

            .dashboard-tile-header {
                background-color: $colorGreyLight4;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding: $spacingMedium $spacingLarge;

                .grid-header-driver {
                    width: 25%;

                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }

                .grid-header-left {
                    width: 35%;

                    h3 {
                        margin: 0;
                        font-size: $textBig;
                        font-weight: 500;
                        width: 100%;
                    }
                }

                h3 {
                    width: 50%;
                }

                .grid-header-right {
                    width: 35%;

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        padding: 0;

                        li {
                            list-style-type: none;

                            &:not(:first-child) {
                                margin-left: $spacingMedium;
                            }

                            &:hover {
                                cursor: pointer;
                            }

                            .grid-count {
                                background-color: $colorGreenLighter;
                                border-radius: 50%;
                                width: $spacingWide;
                                height: $spacingWide;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }

            .grid-content {
                padding: $spacingMedium;
                background-color: $colorWhite;

                ul {
                    margin: $spacingMedium;
                    padding: $spacingMedium $spacingExtraLarge;
                    border: 1px solid $colorGreyLight3;

                    li {
                        list-style-type: none;

                        &:not(:first-child) {
                            margin-top: $spacingMedium;
                            padding-top: $spacingMedium;
                            border-top: 1px solid $colorGreyLight3;
                        }

                        h4 {
                            font-weight: 500;
                            font-size: $textNormal;
                            margin: 0;
                        }

                        p {
                            color: $colorGreyDark1;
                            margin-top: $spacingExtraSmall;
                        }
                    }
                }
            }
        }
    }
}


.settings-view-holder {
    padding: $spacingExtraSmall $spacingExtraSmall $spacingTiny;

    height: 100vh;
    overflow-y: auto;

    .ant-space {
        margin-bottom: $spacingExtraSmall;

        h2 {
            margin-bottom: 0;
            font-size: 18px;
        }
    }

    .ant-space-item {
        button {
            font-size: 12px !important;
            font-weight: 500;
            margin-left: 20px;
            color: #000;
            background-color: #edf0f7;
        }
    }

    .view-top-left {

        h1 {
            display: inline-block;
        }

        .grey-btn {
            margin-left: $spacingExtraExtraLarge;
        }

        select {
            background-color: $colorGrey;
            padding: $spacingExtraSmall $spacingExtraExtraLarge;
            border-radius: $spacingLarge;
            border: none;
        }
    }

    .view-top-right {
        text-align: right;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;

        .views {
            margin: 0 $spacingExtraLarge;

            ul {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;

                li {
                    list-style-type: none;
                    background: $colorGreyLight4;
                    border-radius: 50%;
                    width: $spacingWide;
                    height: $spacingWide;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    line-height: $spacingWide;

                    &:first-child {
                        margin-right: $spacingMedium;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    svg {
                        fill: $colorBlack;
                    }
                }

                li.active {
                    background-color: $colorBlack;

                    img {
                        filter: invert(1);
                    }
                }
            }
        }

        .list-view-search {
            position: relative;

            input {
                border: 1px solid $colorGreyLight2;
                background-color: transparent;
                border-radius: 16px;
                padding: $spacingExtraSmall $spacingWide $spacingExtraSmall $spacingLarge;
                width: 259px;
            }

            img {
                position: absolute;
                right: $spacingMedium;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }

        .list-view-filter {
            border: 1px solid #dfe3ef;
            background: #edf0f7;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-left: $spacingExtraLarge;

            &:hover {
                cursor: pointer;
            }

            .list-filter-dropdown {
                position: absolute;
                z-index: 2;
                top: $spacingWide;
                left: -175px;
                width: 224px;
                background-color: $colorWhite;
                box-shadow: 0 3px $spacingExtraSmall -4px rgb(0 0 0 / 12%), 0 $spacingExtraSmall 1 $spacingExtraSmall 0 rgb(0 0 0 / 8%), 0 9px $spacingExtraExtraLarge $spacingExtraSmall rgb(0 0 0 / 5%);
                border-radius: 2px;

                ul {
                    max-height: 308px;
                    height: 100%;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: $spacingExtraSmall;
                        background: $colorGreyDark4;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $colorGreyDark3;
                        height: 3px;
                        border-radius: $spacingTiny;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: $colorGreyDark3;
                    }

                    li {
                        list-style-type: none;
                        text-align: left;
                        border-bottom: 1px solid $colorGreyLight4;

                        &:hover {
                            background-color: $colorGreyLight4;
                        }

                        .ant-checkbox {
                            display: none;
                        }

                        label {
                            padding: $spacingExtraSmall $spacingMedium;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}


.role-grid {
    padding: 4px 4px 4px 0;
    margin: 4px 4px 4px 0;

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 8px;
    }

    .role-switch {
        margin: 8px 0;
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
        justify-content: flex-end;
    }
}

.ant-layout {
    .ant-layout-header {
        margin-top: $spacingExtraSmall;
        background: $colorGreyLight0 !important;

        .ant-space-item {
            line-height: normal;

            h2 {
                font-size: 18px;
            }

            button {
                font-size: 12px !important;
                font-weight: 500;
                margin-left: 20px;
                color: #000;
                background-color: #edf0f7;
                height: 32px;
            }
        }

        .ant-space {
            width: 100%;
            justify-content: space-between;
        }
    }

    .ant-layout-sider-light {
        &.holotrak-sider-settings {
            background-color: $colorGreyLight0;

            .ant-layout-sider-children {
                padding-left: $spacingExtraSmall;
            }

            &.holotrak-sider-settings--main {
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, .3);
                margin-right: $spacingExtraSmall;

                h3 {
                    padding: $spacingLessThanWide $spacingLessThanLarge;
                    background: white;
                    font-weight: 600;
                    font-size: 18px;
                }
            }


            .ant-menu {
                background-color: $colorGreyLight0;
                padding-left: $spacingSmall;

                .ant-menu-item-group-title {
                    font-size: $textLarge;
                }

                .ant-menu-submenu-title,
                .ant-menu-item {
                    padding-left: 0 !important; // TODO: Find the Design token for Padding Left in ANTD
                    margin-inline: 0;
                    margin-block: 0;
                    height: $spacingLessWide;

                    .ant-menu-item-icon {
                        display: none;
                    }

                    .ant-menu-title-content {
                        margin-inline-start: 0;
                        font-size: $textNormal;
                        color: $colorBlackTransparent;
                        font-weight: 500;
                    }

                    &.ant-menu-item-selected,
                    &:hover {
                        background-color: $colorGreyLight0;

                        .ant-menu-title-content {
                            color: $colorGreen;
                        }
                    }
                }

                .ant-menu-submenu-title .ant-menu-title-content,
                .ant-menu-item-group-title {
                    text-transform: uppercase;
                    font-weight: 600;
                    color: $colorBlack;
                    padding: $spacingExtraSmall 0;
                }
            }

            &.holotrak-sider-settings--devices {
                height: 100vh;
                overflow-y: auto;

                .ant-input-search {
                    padding: $spacingSmall $spacingTiny;
                }
            }
        }
    }
}

.form-main {
    .ant-form-item {
        .ant-form-item-label {
            label {
                width: 200px;
                font-size: 12px;
                font-weight: 700;
            }
        }

        .ant-form-item-control-input-content {
            button {
                margin-left: 30px;
            }
        }
    }

    .ant-input,
    .ant-select-selector {
        height: 40px !important;
        border-radius: 30px;
        padding: 0 15px !important;
        display: flex;
        align-items: center;
        background-color: #fff !important;
    }
}

.ant-layout-has-sider {
    .ant-layout-content {
        background: $colorGreyLight0;
    }
}

.holotrak-settings-menu {
    &.holotrak-settings-menu--devices {
        .ant-menu-title-content {
            align-items: center;
        }
    }

    li.ant-menu-item {
        flex-direction: row-reverse;
    }

    .ant-menu-title-content {
        display: flex;
        justify-content: space-between;
    }

    .ant-space-item {
        font-size: 12px !important;
    }

    .anticon-right {
        font-size: 10px !important;
    }
}

.ant-table-wrapper {
    width: 100%;

    .ant-spin-nested-loading.settings-spinner {
        padding: 50px;

        .ant-table-cell {
            background: transparent;
            font-size: 10px;
            font-weight: 500;

            .ant-typography {
                width: 98%;
            }

            div {
                margin-left: 12px;
            }
        }
    }
}

.ant-layout {
    .ant-layout-header {
        .ant-space-item {
            button.line {
                background-color: #000;
                color: #fff;
                width: 32px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    filter: invert(1);
                }
            }

            button.box {
                width: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;
                margin-left: 0;
            }
        }
    }
}

.search-device-new {
    input {
        border: 1px solid #efefef;
        background-color: transparent;
        border-radius: 16px !important;
        padding: 6px 30px 6px 15px;
        width: 259px !important;
        color: #000;
        font-weight: 500;
    }

    span.ant-input-group-addon {
        width: 100%;
        background: transparent;

        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            right: 0;
            background-color: transparent !important;
            border: none;
        }
    }

    .list-view-filter {
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.export-activity {
    background: transparent;

    &.export-activity--container {
        background: $colorGrey1;
        border: none;
    }
}

.ant-table-content {
    .ant-table-thead,
    .ant-table-tbody {
        .ant-table-cell {
            font-size: 12px;
        }
    }
}


.view-permissions {
    .ant-table-wrapper .ant-spin-nested-loading {
        padding: 0;

        .ant-table-cell div {
            margin: 0;
        }
    }
}
