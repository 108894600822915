
          $font-path: "/Users/harsh/Development/Projects/holotrak/01-code/github/synced-holotrak-web/src/assets/fonts";
        
$fontFamily: 'SFProDisplay';

//Colors Starts
$colorGreyDark1: #3D4A57;
$colorGreyDark2: #7E7E7E;
$colorGreyDark3: #979797;
$colorGreyDark4: #D1D1D1;
$colorGreyDark5: #D6D6D6;
$colorGreyDark6: #DBDFE3;
$colorGrey: #DFE3EF;
$colorGrey1: #edf0f7;
$colorGreyLight0: #F8F8F8;
$colorGreyLightBlue: #eef4f5;
$colorGreyLight1: #F9F9F9;
$colorGreyLight2: #F3F3F3;
$colorGreyLight3: #F0F0F0;
$colorGreyLight4: #E0E3E7;

$colorWhite: #FFF;
$colorMuted: #909FAF;
$colorBlack: #000000;
$colorBlackTransparent: #000000D9;
$colorBlackLighter: #282930;
$colorBG: $colorBlack;

$colorYellowDark: #FFA72A;
$colorYellow: #FFB100;
$colorYellowLight: #FFD576;

$colorGreenDarker: #2AC19F7F;
$colorGreenDark: #2BA895;
$colorGreen: #2AC19F;
$colorGreenLight: #29C19E;
$colorGreenLighter: #A8D6CF;

$colorBlue: #48C0FF;
$colorBlueLight: #9BD8CF;
$colorBlueLighter: #89E5D1;
$colorBlueLightest: #9CE8D7;

$colorPink: #F9979A;
$colorPinkDarkest: #F28287;
$colorPinkDarker: #F88387;
$colorPinkDark: #FF8989;
$colorPinkLight: #FFC3C5;

$shadowColorLabelLight: $colorWhite;
$shadowColorLabelDark: #00000066;

$textColorLabelLight: $colorBlack;
$textColorLabelDark: #cccccc;

//Colors Ends

// Font Sizes Starts
$textBig: 18px;
$textExtraLarge: 16px;
$textLarge: 14px;
$textNormal: 12px;
$textSuper: 11px;
$textSmall: 10px;
$textExtraSmall: 9px;
$textTiny: 8px;
// Font Sizes Ends


// Space Starts
$spacingNone: 0px;
$spacingMicro: 2px;
$spacingTiny: 4px;
$spacingLargeTiny: 6px;
$spacingExtraSmall: 8px;
$spacingSmall: 10px;
$spacingMedium: 12px;
$spacingLessThanLarge: 14px;
$spacingLarge: 16px;
$spacingLarge-1: 18px;
$spacingExtraLarge: 20px;
$spacingExtraExtraLarge: 24px;
$spacingExtraExtraExtraLarge: 26px;
$spacingLessThanWide: 28px;
$spacingLessWide: 30px;
$spacingWide: 32px;
$spacingExtraWide: 34px;
$spacingUltraWide: 36px;
$spacingSmall4x: 40px;
$spacingExtended: 42px;
$spacingDouble: 48px;
$spacingExtraDouble: 60px;
// Space Ends

// Indoor Assets
$colorContainer: #2b2b2b;
$colorWall: #2a404b;
$colorFloor: #2a404b; // #595959
$colorText: #5d6975;
$colorOutlines: #5a5a5a;
$colorStairsOuter: $colorOutlines; //#3f444a;
$colorHighlight: #8d5818;

.dashboard-container {
  height: 100vh;
  overflow-y: auto;

  .ant-typography {
    font-size: $textLarge;
    line-height: inherit;
  }

  .dashboard-top {
    margin-top: $spacingSmall4x;
    justify-content: space-between;
  }

  .ant-spin-container {
    padding: $spacingExtraDouble;
    height: 100%;
  }

  button.button-link {
    border: 1px solid $colorGrey1;
    background: $colorGrey1;
    padding: $spacingExtraSmall $spacingLessThanWide;
    font-weight: bold;
    transition: 300ms ease-in-out;
    line-height: 14px;
    box-shadow: none;

    &:hover {
      color: $colorWhite;
      background: $colorGreen;

      a {
        color: $colorWhite;
        transition: none;
      }
    }
  }

  a.icon-link {
    height: $spacingLessThanWide;
    width: $spacingLessThanWide;
    position: absolute;
    right: 0;

    button {
      padding: $spacingNone;
      margin: $spacingNone;
      height: $spacingExtraExtraExtraLarge;
      width: $spacingExtraExtraExtraLarge;

      span {
        height: $spacingExtraExtraExtraLarge;
        width: $spacingExtraExtraExtraLarge;

        svg {
          height: $spacingExtraExtraExtraLarge;
          width: $spacingExtraExtraExtraLarge;
        }
      }
    }
  }

  .ant-layout-content {
    .dashboard-header .dashboard-header-tile {
      display: flex;
      align-items: center;

      &.dashboard-header-left {
        width: 67%;
      }

      &.dashboard-header-right {
        width: 33%;
      }
    }


    h5 {
      margin: 0 $spacingSmall4x 0 0;
      font-size: $textBig;
      line-height: 25px;
    }

    .dashboard-tile {
      padding-right: $spacingLarge;

      &.ant-col-8 {
        background-color: #fff;
        width: calc((100% - 25px) / 3);
        height: 200px;
        padding: $spacingLarge-1;
        border-radius: 6px;
        flex: 0 0 auto;

        .apexcharts-canvas {
          margin-left: -18px;
        }
      }

      .dashboard-tile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacingNone $spacingNone $spacingLessWide;
      }

      .dashboard-tile-content {
        ul.stat-list {
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            cursor: pointer;

            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }

            &:not(:last-child) {
              border-bottom: 1px solid #e5e5e5;
            }

            &.no-separation {
              border-bottom: none;
            }

            padding: $spacingSmall $spacingNone;

            .icon {
              margin-right: $spacingLargeTiny;
              position: relative;
              top: -2px;
            }

            .dashboard-status {
              margin-right: $spacingLargeTiny;
              display: inline-block;
              height: 8px;
              width: 8px;
              border-radius: 8px;
              position: relative;
              top: -1px;
            }

            font-weight: 700;
            line-height: inherit;
            font-size: 14px;

          }
        }
      }

      &.dashboard-tile-with-chart .dashboard-tile-content {
        display: flex;
        background: $colorWhite;
        align-items: center;
      }
    }

  }
}

.dashboard-bottom {
  margin-top: $spacingDouble;
  display: flex;
  justify-content: space-between;
  flex: inherit;

  .ant-col.ant-col-6 {
    width: calc((100% - 40px) / 4);
    padding-right: 0;
    flex: 0 0 auto;

    .stat-list {
      width: 80%;
    }

    .dashboard-tile-content {
      background: #fff;
      background-color: #fff;
      padding: $spacingLarge-1 $spacingExtraLarge;
      border-radius: 6px;
      height: 192px;

      .ant-space-item {
        padding-bottom: 2px;
      }

      button.button-link {
        width: 100%;
        padding-top: 7px;
        padding-bottom: 7px;
        height: 30px;
      }

      ul.stat-list {
        .stat-list-item {
          .icon {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

footer.ant-layout-footer.bottom-logo {
  background: #f8f8f8;
  padding-bottom: 30px;
}
